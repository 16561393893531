import React, {Component} from 'react'

class BaseComponent extends Component {

    render() {
        return (<div></div>)
    }

    componentDidMount() {
        var menu = document.getElementById("dropdown-menu");
        if(menu) {
            menu.classList.remove("nav-expand");
            menu.classList.add("nav-collapse");
        }
    }
  }

export default BaseComponent
