import React, { useState } from "react";
import "../css/Header.css";
import { Link } from "react-router-dom";
import burger from "../static/icons/hamburger.svg";
import logo from "../static/images/logo_name.png";

function Header() {
  const [toggle, Expand] = useState(false);

  const linkStyle = { textDecoration: "none", color: "black" };

  return (
    <div className="header-container full-width">
      <div className="header">
        <Link style={linkStyle} to="/">
          <img src={logo} className="logo" alt="logo" />
        </Link>
        <img
          id="burger"
          src={burger}
          alt="burger"
          onClick={() => Expand(!toggle)}
        />
        <div
          id="dropdown-menu"
          className={`menu ${toggle ? "nav-expand" : "nav-collapse"}`}
        >
          <Link className="nav-item" to="/about">
            About
          </Link>
          {/* <Link className="nav-item" to='/blog'>
                        Blog
                    </Link> */}
          <a href="#footer" className="nav-item">
            Contacts
          </a>
        </div>
        <div
          className="overlay"
          onClick={() => Expand(!toggle)}
          style={{ display: `${toggle ? "block" : "none"}` }}
        ></div>
      </div>
    </div>
  );
}

export default Header;
