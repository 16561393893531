import React, { Component } from 'react'
import axios from 'axios'
import '../../css/Post.css';
import clock from '../../static/icons/clock.svg'
import ReactHtmlParser from 'react-html-parser';
import { Helmet } from "react-helmet";
import {decode} from 'html-entities';
import share from '../../static/icons/share.svg'
import BaseComponent from './base';


const USER_SERVICE_URL = 'https://wp.equusight.com/wp-json/wp/v2/posts';


class Post extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            isFetching: false,
            post: []
        };
        
        this.slug = window.location.pathname.replace("/blog/", "").replace("/blog", "").replace("/news/", "").replace("/news", "").toLowerCase();
        this.currentUrl = window.location.href;
    }

    render() {
        return (
            <div className="post-container">
                <Helmet>
                    <title>{this.state.post.title ? "EquuSight news: " + decode(this.state.post.title.rendered) : ""}</title>
                    <meta name="title" content={this.state.post.title ? "EquuSight news: " + decode(this.state.post.title.rendered) : ""}/>
                    <meta name="description" content={this.state.post.excerpt ? this.state.post.excerpt.rendered.replace(/<\/?[^>]+(>|$)/g, "") : ""} />
                    <link rel="canonical" href={encodeURI(this.currentUrl)}/>
                </Helmet>
                <div className="post-area">
                    {
                        this.DisplayPost(this.state.post)
                    }
                    <p>{this.state.isFetching ? 'Fetching post...' : ''}</p>
                </div>
            </div>
        )
    }

    componentDidMount() {
        this.fetchPost();
        this.timer = setInterval(() => this.fetchPost(), 5000);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
        this.timer = null;
    }

    DisplayPost(post) {
        if(!post || post.length === 0) return;
        clearInterval(this.timer);
        window.scrollTo(0,0);
        this.timer = null;
        return (
            <div className="post" id={post.id}>
                <h1>{decode(post.title.rendered)}</h1>
                <div className="subtitle">
                    <span className="category">{post.category}</span>
                    <div className="date">
                            <img src={clock} alt="clock" />
                            <span className="date">{(new Date(post.modified)).toDateString()}</span>
                        </div>
                    <a className="share" target="_blank" rel="noopener noreferrer" href={`https://www.facebook.com/sharer.php?u=${encodeURI(this.currentUrl)}`}>
                        <img src={share} width="30px" height="30px" alt="share" data-tip="Share this on facebook" data-place="right"/>    
                    </a>
                </div>
                {this.DisplayFeaturedImage(post)}
                <div className="post-content">
                    {ReactHtmlParser(post.content.rendered)}
                </div>
            </div>
        )
    }

    DisplayFeaturedImage(post) {
        if(post.featuredImage) {
            return (<div className="featured-image-container">
                <img className="featured-image" alt={decode(post.title.rendered)} src={post.featuredImage}/>
            </div>)
        }
    }

    ProcessHtml(html) {
        if (html.includes("<button>")) {
            let regex = /<button>(.*?)<\/button>/;

            var matches = [];
            var match;
            var temp = html;
            while ((match = regex.exec(temp)) != null) {
                if (match == null) break;
                matches.push(match[0]);
                temp = temp.replace(regex, '');
            }

            matches.forEach(item => {
                var element = item;
                var url = element.replace("<button>", "").replace("</button>", "");
                html = html.replace(item, `<a class="read-more" rel="noopener noreferrer" target="_blank" href="${url}">Read more</a>`);
            })
        }
        return html;
    }


    async fetchPostAsync() {
        try {
            this.setState({ ...this.state, isFetching: true });
            const response = await axios.get(USER_SERVICE_URL);
            var post =  response.data.find(post => post.slug === this.slug);
            post.featuredImage = await this.fetchFeaturedImageAsync(post.featured_media);
            
            if(post.categories.length > 0) {
                post.category = await this.fetchPostCategoryAsync(post.id);
            }
            
            this.setState({ post: post, isFetching: false });
        } catch (e) {
            console.log(e);
            this.setState({ ...this.state, isFetching: false });
        }
    };

    async fetchFeaturedImageAsync(imageId) {
        try {
            // if(!imageId) {
            //     return require("../../static/images/Equusight_banner.jpg");
            // }
            const response = await axios.get(`https://wp.equusight.com/wp-json/wp/v2/media/${imageId}`);
            return response.data.guid.rendered;
        } catch (e) {
            console.log(e);
            this.setState({ ...this.state, isFetching: false });
        }
    };

    async fetchPostCategoryAsync(postId) {
        try {
            const response = await axios.get(`https://wp.equusight.com/wp-json/wp/v2/categories?post=${postId}`);
            
            return response.data[0].name;
        } catch (e) {
            console.log(e);
            this.setState({ ...this.state, isFetching: false });
        }
    };

    fetchPost = this.fetchPostAsync;
}

export default Post
