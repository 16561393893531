import React from 'react';
import Header from './Header'; 
import Footer from './Footer';
import Home from './Home';
import About from './About';
import Blog from './Blog';
import Post from './components/Post';
import '../css/App.css';

import {
    BrowserRouter as Router,
    Switch,
    Route,
  } from "react-router-dom";

function App() {
    return (
        <div className="app">
            <Router>
                <Header/>
                <Switch>
                    <Route exact path="/">
                        <Home/>
                    </Route>
                    <Route exact path="/about">
                        <About/>
                    </Route>
                    <Route exact path="/news">
                        <Blog/>
                    </Route>
                    <Route exact path="/news/:post?">
                        <Post/>
                    </Route>
                    <Route exact path="/blog">
                        <Blog/>
                    </Route>
                    <Route exact path="/blog/:post?">
                        <Post/>
                    </Route>
                    <Route exact path="*">
                        <Home/>
                    </Route>
                </Switch>
                <Footer/>
            </Router>
        </div>
    );
}

export default App;
