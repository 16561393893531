import React, { Component } from 'react'
import axios from 'axios'
import '../../css/LatestPost.css';
import clock from '../../static/icons/clock.svg'
import ReactHtmlParser from 'react-html-parser';
import { decode } from 'html-entities';
import { Link } from 'react-router-dom';



const USER_SERVICE_URL = 'https://wp.equusight.com/wp-json/wp/v2/posts';


class LatestPost extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isFetching: false,
            post: []
        };
        this.slug = window.location.pathname.replace("/blog/", "").replace("/blog", "").toLowerCase();
    }

    render() {
        return (
            <div className="post-area">
                {
                    this.DisplayPost(this.state.post)
                }
                <p>{this.state.isFetching ? 'Fetching post...' : ''}</p>
            </div>
        )
    }

    componentDidMount() {
        this.fetchPost();
        this.timer = setInterval(() => this.fetchPost(), 5000);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
        this.timer = null;
    }

    DisplayPost(post) {
        if (!post || post.length === 0) return;
        clearInterval(this.timer);
        this.timer = null;
        return (
            <div className="latest-post" id={post.id}>
                <div className="first-column">
                    <img className="featured-image" alt={decode(post.title.rendered)} src={post.featuredImage} />
                </div>
                <div className="second-column">
                <Link className="read-more" to={"/blog/" + post.slug}><h3>{decode(post.title.rendered)}</h3></Link>
                    <div className="subtitle">
                        <span className="category">{post.category}</span>
                        <div className="date">
                            <img src={clock} alt="clock" />
                            <span className="date">{(new Date(post.modified)).toDateString()}</span>
                        </div>
                    </div>
                    <div className="post-content">
                        {ReactHtmlParser(post.excerpt.rendered)}
                    </div>
                </div>
            </div>
        )
    }


    async fetchPostAsync() {
        try {
            this.setState({ ...this.state, isFetching: true });
            var posts = JSON.parse(sessionStorage.getItem("posts"));
            if(!posts) {
                const response = await axios.get(USER_SERVICE_URL);
                posts = response.data;
                sessionStorage.setItem("posts", JSON.stringify(posts));
            }
            let post = posts[0];
            post.featuredImage = await this.fetchFeaturedImageAsync(post.featured_media);
            
            if (post.categories.length > 0) {
                post.category = await this.fetchPostCategoryAsync(post.id);
            }

            this.setState({ post: post, isFetching: false });
        } catch (e) {
            console.log(e);
            this.setState({ ...this.state, isFetching: false });
        }
    };

    async fetchPostCategoryAsync(postId) {
        try {
            const response = await axios.get(`https://wp.equusight.com/wp-json/wp/v2/categories?post=${postId}`);
            
            return response.data[0].name;
        } catch (e) {
            console.log(e);
            this.setState({ ...this.state, isFetching: false });
        }
    };

    async fetchFeaturedImageAsync(imageId) {
        try {
            if(!imageId) {
                return require("../../static/images/Equusight_banner.jpg");
            }
            const response = await axios.get(`https://wp.equusight.com/wp-json/wp/v2/media/${imageId}`);
            return response.data.guid.rendered;
        } catch (e) {
            console.log(e);
            this.setState({ ...this.state, isFetching: false });
        }
    };

    fetchPost = this.fetchPostAsync;
}

export default LatestPost
