import React from "react";
import "../css/Footer.css";
import locationSVG from "../static/icons/location.svg";
import phoneSVG from "../static/icons/phone.svg";
import chatSVG from "../static/icons/chat.svg";

import facebook from "../static/icons/facebook.svg";
import linkedin from "../static/icons/linkedin.svg";
import instagram from "../static/icons/instagram.svg";

function Footer() {
  return (
    <div id="footer" className="footer">
      <div className="footer-container">
        <div className="contacts">
          <h3>CONTACT DETAILS</h3>
          <div className="contacts-line">
            <img src={locationSVG} alt="location" />
            <div className="contacts-line-text">
              148 Mavety St, Toronto, Canada
            </div>
          </div>
          <div className="contacts-line">
            <img src={phoneSVG} alt="contacts" />
            <div className="contacts-line-text">+16478559213</div>
          </div>
          <div className="contacts-line">
            <img src={chatSVG} alt="chat" />
            <div className="contacts-line-text">rugiledauliute@gmail.com</div>
          </div>
        </div>
        <div className="column">
          <div className="info">
            <h3>ABOUT THE COMPANY</h3>
            <p>
              LP “Innovatio veterinariae” is a company that develops advanced
              veterinary technology solutions.
            </p>
          </div>
          <div className="media">
            <a
              className="media-link"
              href="https://www.facebook.com/EquuSight-148408846962322"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={facebook} alt="facebook" />
            </a>
            <a
              className="media-link"
              href="https://www.linkedin.com/company/equusight/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={linkedin} alt="linkedin" />
            </a>
            <a
              className="media-link"
              href="https://www.instagram.com/equu.sight/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={instagram} alt="linkedin" />
            </a>
          </div>
        </div>
      </div>

      <div className="footer-text">©2024 by LP "Innovatio veterinariae"</div>
    </div>
  );
}

export default Footer;
