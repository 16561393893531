import React, {Component} from 'react'
import axios from 'axios'
import '../../css/Posts.css';

import clock from '../../static/icons/clock.svg'
import ReactHtmlParser from 'react-html-parser';
import { Link } from 'react-router-dom';
import { decode } from 'html-entities';

const USER_SERVICE_URL = 'https://wp.equusight.com/wp-json/wp/v2/posts';


class Posts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isFetching: false,
            posts: []
        };
    }

    render() {
        return (
            <div className="posts-container">
                {this.state.posts.map(post => {
                    return this.DisplayPost(post);
                })}
                <p>{this.state.isFetching ? 'Fetching posts...' : ''}</p>
            </div>
        )
    }

    componentDidMount() {
        this.fetchPosts();
        this.timer = setInterval(() => this.fetchPosts(), 5000);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
        this.timer = null;
    }


    DisplayPost(post) {
        clearInterval(this.timer);
        window.scrollTo(0,0);
        this.timer = null;
        return (
            <div className="blog-post" id={post.id}>
                <div className="text-container">
                    <div className="date">
                        <img src={clock} alt="clock" />
                        <span>{(new Date(post.modified)).toDateString()}</span>
                    </div>
                    <Link className="post-title" to={"/blog/" + post.slug}><h3>{decode(post.title.rendered)}</h3></Link>
                    <div className="excerpt" style={{ height: `200px` }}>
                        {ReactHtmlParser(this.ProcessHtml(post.excerpt.rendered))}
                    </div>
                    <Link className="read-more" to={"/blog/" + post.slug}>Read more</Link>
                </div>
            </div>
        )
    }

    
    ProcessHtml(html) {
    if (html.includes("<button>")) {
        let regex = /<button>(.*?)<\/button>/;
            
        var matches = [];
        var match;
        var temp = html;
        while ((match = regex.exec(temp)) != null) {
            if(match == null) break;
            matches.push(match[0]);
            temp = temp.replace(regex, '');
        }

        matches.forEach(item => {
            var element = item;
            var url = element.replace("<button>", "").replace("</button>", "");
            html = html.replace(item, `<a class="read-more" rel="noopener noreferrer" target="_blank" href="${url}">Read more</a>`);
        })
    }
    return html;
}


    async fetchPostsAsync() {
        try {
            this.setState({...this.state, isFetching: true});
            var posts = JSON.parse(sessionStorage.getItem("posts"));
            if(!posts) {
                const response = await axios.get(USER_SERVICE_URL);
                posts = response.data;
                sessionStorage.setItem("posts", JSON.stringify(posts));
            }
            this.setState({posts: posts, isFetching: false});
        } catch (e) {
            console.log(e);
            this.setState({...this.state, isFetching: false});
        }
    };

    async fetchFeaturedImageAsync(imageId) {
        try {
            const response = await axios.get(`https://wp.equusight.com/wp-json/wp/v2/media/${imageId}`);
            return response.data.guid.rendered;
        } catch (e) {
            console.log(e);
            this.setState({ ...this.state, isFetching: false });
        }
    };

    async fetchPostCategoryAsync(postId) {
        try {
            const response = await axios.get(`https://wp.equusight.com/wp-json/wp/v2/categories?post=${postId}`);
            
            return response.data[0].name;
        } catch (e) {
            console.log(e);
            this.setState({ ...this.state, isFetching: false });
        }
    };

    fetchPosts = this.fetchPostsAsync;
}

export default Posts
