import React from 'react';
import '../css/About.css';
import { Helmet } from "react-helmet";
import BaseComponent from './components/base';


import brand1 from '../static/images/brands/oxipit.png';
import brand2 from '../static/images/brands/softneta.png';
import brand3 from '../static/images/partners/lsmu.png';
import brand4 from '../static/images/brands/meslay.png';
import brand5 from '../static/images/brands/hojgard.png';
import brand6 from '../static/images/partners/almargem.png';

function About() {

    const team = [
        [
            {
                Id: 1,
                Name: "Rugile Dauliute",
                Image: "Rugile.jpg",
                Role: "FOUNDER",
                Description: "Rugile is a veterinarian and has well over 10 years of national and international experience working with horses as a rider, trainer and as a veterinary practitioner. She is the team’s leader and subject matter expert coordinating partnerships and product development.",
            },
            {
                Id: 2,
                Name: "Eimantas Macius",
                Image: "Eimantas.jpg",
                Role: "CO-FOUNDER",
                Description: "Eimantas is an IT specialist. He is a full-time software tester and software developer in international companies. Eimantas specialises in turning business needs into technical solutions.",
            },
        ],
        [
            {
                Id: 3,
                Name: "Martynas Bartkus",
                Image: "Martynas.jpg",
                Role: "DEVELOPER",
                Description: "Martynas is a full time software developer who specialises in front-end and databases. Martynas is a tech geek with years of experience in creating technologically driven solutions.",
            },
            {
                Id: 4,
                Name: "Vilius Vaiciulis",
                Image: "Vilius.jpg",
                Role: "DEVELOPER",
                Description: "Vilius works as a software developer mostly in developing back-end solutions. Vilius also works at another startup and evolves AI models.",
            },
        ]
    ];

    const dataTeam = [
        [
            {
                Id: 1,
            },
            {
                Id: 2,
                Name: "SARA SÊRRO FIDALGO",
                Image: "Sara.png",
                Role: "EQUINE DVM, MRCVS",
                Description: "Sara specialises in equine lameness & emergency medicine. She is currently working in Wendover Heights Veterinary Centre in the UK and studying for a certificate in Advanced Veterinary Practice specialising in Equine Lameness Diagnosis and Therapeutics.",
            }
        ],
        [
            {
                Id: 3,
                Name: "GIEDRE VILENISKE",
                Image: "Giedre.png",
                Role: "EQUINE DVM, EDO",
                Description: "Giedre specialises in orthopaedics, biomechanics & equine osteopathy. She is currently doing PhD studies & giving lectures at the Lithuanian University of Health Sciences. She is an Equine Osteopathy Diplomate & Specialist in Animal Fascial Manipulation.",
            },
            {
                Id: 4,
                Name: "Dr. JULIUS RUSECKAS",
                Image: "Julius.png",
                Role: "MACHINE LEARNING EXPERT",
                Description: "Julius specialises in machine learning. He is a researcher at the Baltic Institute of Advanced Technology (BPTI) and research professor at the Institute of Theoretical Physics and Astronomy in Vilnius University.",
            }
        ]
    ];


    return (
        <div>
            <BaseComponent/>
            <Helmet>
                    <title>About EquuSight</title>
                    <meta name="title" content="About EquuSight" />
                    <meta name="description" content="Our core project  - “EQUUSIGHT” - is an artificial intelligence-based system for automated horse X-ray analysis." />
                    <meta name="keywords" content="equusight, equine, equus, team, about, company, team members, project" />
                    
                    <link rel="canonical" href={encodeURI(window.location.href)}/>
                </Helmet>
            <div className="overview-container">
                <div className="overview-text-container-container">
                    <div className="overview-text-container">
                        <h1 className="overview-text-title">
                            EQUUSIGHT PROJECT OVERVIEW
                        </h1>
                        <p className="overview-text-line">
                            Our core project - “EQUUSIGHT” is an artificial intelligence-based system for automated equine X-ray analysis.
                        </p>
                        <p className="overview-text-line">
                            EQUUSIGHT has been introduced at various national and international events, forums and conferences including those organised by the European Innovation and Technology Institute and world-leading medical enterprise “GE Healthcare”.
                        </p>
                    </div>
                </div>
            </div>
            <div className="section-title">MEET THE TEAM</div>
            <div className="full-width-center">
                <div className="team-container">

                    {team.map(pair => {
                        return (
                            <div key={Math.floor(Math.random() * 1000)} className="member-pair" >
                                {pair.map(member => {

                                    return (
                                        <div key={member.Id} className="team-member-container" style={{ height: `${member.Role ? '' : '0px'}` }}>
                                            <div className="member-photo-container">
                                                <img src={require("../static/images/Team/" + member.Image)} width="200px" alt={member.Name} />
                                            </div>
                                            <div className="role-container">
                                                <div className="role" style={{ display: `${member.Role ? 'flex' : 'none'}` }}>
                                                    <div className="role-text">{member.Role}</div>
                                                </div>
                                            </div>
                                            <div className="member-description">
                                                <div className="member-name">{member.Name}</div>
                                                <p className="member-description-text">{member.Description}</p>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="section-title">DATA PROCESSING TEAM</div>
            <div className="full-width-center">
                <div className="team-container">

                    {dataTeam.map(pair => {
                        return (
                            <div key={Math.floor(Math.random() * 1000)} className="member-pair" >
                                {pair.map(member => {
                                    if (!member.Name) {
                                        return (
                                            <div key={member.Id} className="team-member-container stats" >
                                                <div className="trivia">
                                                    <div>
                                                        <span>10</span>
                                                        <span>international</span>
                                                        <span>Veterinary</span>
                                                        <span>doctors</span>
                                                    </div>
                                                    <div>
                                                        <span>14</span>
                                                        <span>international</span>
                                                        <span>Veterinary</span>
                                                        <span>students</span>
                                                    </div>
                                                </div>
                                            </div>
                                            )
                                    }
                                    return (
                                        <div key={member.Id} className="team-member-container" >
                                            <div className="member-photo-container">
                                                <img src={require("../static/images/Team/" + member.Image)} width="200px" alt={member.Name} />
                                            </div>
                                            <div className="role-container">
                                                <div className="role">
                                                    <div className="role-text">{member.Role}</div>
                                                </div>
                                            </div>
                                            <div className="member-description">
                                                <div className="member-name">{member.Name}</div>
                                                <p className="member-description-text">{member.Description}</p>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="section-title">ADVISORY BOARD</div>
            <div className="full-width-center">
                <div className="brands-container">
                    <div className="brand-container">
                        <div className="brand-logo-container">
                            <a href="https://www.oxipit.ai/" target="_blank" rel="noopener noreferrer"><img className="brand-logo" src={brand1} alt="oxipit" /></a>
                        </div>
                        <div className="brand-name-container">
                            <div className="brand-name">
                                MEDICAL IMAGING WITH AI
                            </div>
                        </div>
                        <div className="brand-description-container">
                            <div className="brand-description">
                                AI medical imaging company providing fully automated computer-aided diagnosis (CAD)  for chest X-ray, radiological queue management & similar radiological appearance search in databases worldwide.
                            </div>
                        </div>
                    </div>
                    <div className="brand-container">
                        <div className="brand-logo-container">
                            <a href="https://www.softneta.com/" target="_blank" rel="noopener noreferrer"><img className="brand-logo" src={brand2} alt="softneta" /></a>
                        </div>
                        <div className="brand-name-container">
                            <div className="brand-name">
                                MEDICAL IMAGING &amp; COMMUNICATION SOLUTIONS
                            </div>
                        </div>
                        <div className="brand-description-container">
                            <div className="brand-description">
                                Softneta has 12+ years of experience in the development of medical devices for processing, visualization and transmission of diagnostic medical data in more than 55 countries.
                            </div>
                        </div>
                    </div>
                    <div className="brand-container">
                        <div className="brand-logo-container">
                            <a href="https://lsmuni.lt/en/structure/veterinary-academy/clinics-of-faculty-of-veterinary-medicine/large-animal-clinic.html" target="_blank" rel="noopener noreferrer"><img className="brand-logo" src={brand3} alt="lsmu" /></a>
                        </div>
                        <div className="brand-name-container">
                            <div className="brand-name">
                                LITHUANIAN UNIVERSITY OF HEALTH SCIENCES
                            </div>
                        </div>
                        <div className="brand-description-container">
                            <div className="brand-description">
                                Large Animal Clinic of Veterinary Academy provides all aspects of patient care from on-farm preventive medicine to complex referral care. A team of veterinarians and researchers provide horses, bovines and small ruminants with medical care.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-title">PARTNERSHIPS</div>
            <div style={{ marginBottom: '40px' }} className="full-width-center">
                <div className="brands-container">
                    <div className="brand-container partnership-container">
                        <div className="brand-logo-container">
                            <a href="https://cliniqueequinedemeslay.com/en" target="_blank" rel="noopener noreferrer"><img className="brand-logo" src={brand4} alt="clinique equine de meslay" /></a>
                        </div>
                        <div className="brand-description-container">
                            <div className="brand-description">
                                Clinique Equine de Meslay is one of the largest equine veterinary practices in France, offering first opinion and referral veterinary services. The clinic has a long-established association with the racing and breeding industries around the country.
                            </div>
                        </div>
                    </div>
                    <div className="brand-container partnership-container">
                        <div className="brand-logo-container">
                            <a href="https://dinhestifokus.dk/hojgaard-hestehospital/engelsk/" target="_blank" rel="noopener noreferrer"><img className="brand-logo" src={brand5} alt="hojgard hestehospital" /></a>
                        </div>
                        <div className="brand-description-container">
                            <div className="brand-description">
                                Højgård Equine Hospital is the largest fully-equipped private Equine Hospital in the Denmark. They offer the most advanced diagnostic and surgical techniques in state-of-the-art facilities, and are staffed with progressive specialists in equine soft-tissue and orthopaedic surgery, internal medicine and diagnostic imaging including DR, standing MRI &amp; Scintigraphy.
                            </div>
                        </div>
                    </div>
                    <div className="brand-container partnership-container">
                        <div className="brand-logo-container">
                            <a href="http://www.clinicadoalmargem.pt/index.html" target="_blank" rel="noopener noreferrer"><img className="brand-logo" src={brand6} alt="clinica do almargem" /></a>
                        </div>
                        <div className="brand-description-container">
                            <div className="brand-description">
                                Family-owned veterinary practice in Portugal offering advanced equine dentistry  procedures, largely operating ambulatory practice and novel therapy solutions for horses.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default About;