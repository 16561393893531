import React from 'react';
import { Helmet } from "react-helmet";
import '../css/Blog.css';
import Posts from './components/Posts'
import LatestPost from './components/LatestPost';
import BaseComponent from './components/base';


function Blog() {
    
    return (
        <div>
            <BaseComponent/>
            <Helmet>
                <title>EquuSight News</title>
                <meta name="title" content="EquuSight News" />
                <meta name="description" content="EquuSight is devoted to helping veterinarians in radiologic analysis of equines." />
                <meta name="keywords" content="equusight, equine, equus, news, blog, posts, articles" />
                <link rel="canonical" href={encodeURI(window.location.href)}/>
            </Helmet>

            <div className="full-width-center pt-30 p-sides-20">
                <h1>Find out more about EquuSight from our blog</h1>
            </div>
           <div className="full-width-center">
                <div className="latest-post-container">
                    <LatestPost/>
                </div>
            </div>
            <div className="section-title">Latest EquuSight News</div>
            <div className="full-width-center">
                   <Posts/>
                </div>

            <div style={{ marginBottom: '40px' }} className="full-width-center"></div>
        </div>
    );
    
}

export default Blog;