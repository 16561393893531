import React from 'react';
import '../css/Home.css';
import brochure from '../static/other/brochure.pdf';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";

import product from '../static/images/product.png';
import binoculars from '../static/icons/binoculars.svg';
import doctor from '../static/icons/doctor.svg';
import list from '../static/icons/list.svg';
import BaseComponent from './components/base';


import almargem from '../static/images/partners/almargem.png';
import hojgard from '../static/images/partners/hojgard.png';
import lsmu from '../static/images/partners/lsmu.png';
import meslay from '../static/images/partners/meslay.png';
import oxipit from '../static/images/partners/oxipit.png';
import softneta from '../static/images/partners/softneta.png';


function Home() {

    return (
        <div className="home-container">
            <BaseComponent/>
            <Helmet>
                <title>EquuSight - Empowering Equine Diagnostics</title>
                <meta name="title" content="EquuSight - Empowering Equine Diagnostics" />
                <meta name="description" content="EquuSight is an AI medical imaging company providing fully automated computer-aided diagnosis (CAD)" />
                <meta name="keywords" content="equusight, equine, equus, equine veterinary, equine radiology, horse x-rays, horses" />
                <link rel="canonical" href={encodeURI(window.location.href)}/>
            </Helmet>
            <div className="hero-section">
                <div className="words">
                    <span>EMPOWERING</span>
                    <span>EQUINE</span>
                    <span>DIAGNOSTICS</span>
                    <Link className="about button" to='/about'>ABOUT US</Link>
                </div>
            </div>

            <div className="product-overview-section">
                <div className="product-overview-header">
                    <h2>EQUUSIGHT PRODUCT</h2>
                </div>
                <div className="product-overview-container">
                    <div className="overview-text">
                        Constantly growing number of images and difficult cases force doctors reach out for assistance. EquuSight is there to help! Our
                        artificial intelligence technology analyses patient‘s images while comparing it to thousands of previously examined cases. System provides veterinarian with a list of findings and ensures that no pathology is overlooked.
                    </div>
                    <div className="overview-image">
                        <img src={product} alt="product" />
                    </div>
                </div>
            </div>


            <div className="tech-advantages">
                <div className="tech-advantages-container">

                    <div className="text-box inverted">
                        <div className="paragraph-image">
                            <img src={binoculars} alt="binoculars" />
                        </div>
                        <div className="header-text">
                            <h3>AUTOMATED LESION DETECTION</h3>
                            <p className="text-line">
                                Artificial Intelligence (AI) is being broadly used in medical imaging. AI algorithms help human clinicians reduce error rates, overlooked findings and increase the accuracy of evaluation, by giving an objective second opinion. Innovations in veterinary medicine are emerging and our project is the first of its kind that works specifically on the evaluation of equine radiographs.
                            </p>
                        </div>
                    </div>
                    <div className="text-box inverted">
                        <div className="paragraph-image">
                            <img src={doctor} alt="doctor" />
                        </div>
                        <div className="header-text">
                            <h3>INSTANT SECOND OPINION</h3>
                            <p className="text-line">
                                Radiographic images are not always easy to evaluate. To reach a confident diagnosis, veterinarians often consult books, review articles and consult with colleagues which can delay the image evaluation. AI models are trained on thousands of previously labelled and diagnosed images; allowing computer-aided diagnosis, to give immediate feedback to clinicians.
                                </p>
                        </div>
                    </div>
                    <div className="text-box inverted">
                        <div className="paragraph-image">
                            <img src={list} alt="list" />
                        </div>
                        <div className="header-text">
                            <h3>RETROSPECTIVE STUDIES DISPLAY</h3>
                            <p className="text-line">
                                To publish an article large amounts of studies and literature need to be reviewed. Doctors therefore spend a significant amount of their time reviewing past images and collecting the data for their own research. EQUUSIGHT automates the process while providing lists and details of similar cases.
                                </p>
                        </div>
                    </div>
                </div>
            </div>

            
            <div className="video-section">
                <div className="video-header">
                    <h2>Our solution in a nutshell</h2>
                </div>
                <div className="video-container">
                    <iframe title="EquuSight video" width="560" height="349" src="https://www.youtube.com/embed/0KRj2hW0iXg?modestbranding=1&showinfo=0&rel=0&iv_load_policy=3&fs=0" frameborder="0"></iframe>
                </div>
            </div>



            <div className="partners-section">
                <div className="partners-header">
                    <h2>EQUUSIGHT PARTNERS</h2>
                </div>
                <div className="partners-container">
                    <div className="partner">
                        <a rel="noopener noreferrer" target="_blank" href="http://www.clinicadoalmargem.pt/index.html">
                            <img src={almargem} alt="Almargem" />
                        </a>
                    </div>
                    <div className="partner">
                        <a rel="noopener noreferrer" target="_blank" href="https://dinhestifokus.dk/hojgaard-hestehospital">
                            <img src={hojgard} alt="Hojgard" />
                        </a>
                    </div>
                    <div className="partner">
                        <a rel="noopener noreferrer" target="_blank" href="https://lsmuni.lt/lt/struktura/veterinarijos-akademija/veterinarijos-fakultetas/">
                            <img src={lsmu} alt="Lsmu" />
                        </a>
                    </div>
                    <div className="partner">
                        <a rel="noopener noreferrer" target="_blank" href="https://cliniqueequinedemeslay.com/">
                            <img src={meslay} alt="Meslay" />
                        </a>
                    </div>
                    <div className="partner">
                        <a rel="noopener noreferrer" target="_blank" href="https://oxipit.ai/">
                            <img src={oxipit} alt="Oxipit" />
                        </a>
                    </div>
                    <div className="partner">
                        <a rel="noopener noreferrer" target="_blank" href="https://www.softneta.com/">
                            <img src={softneta} alt="Softneta" />
                        </a>
                    </div>
                </div>
            </div>


            


            <div className="project-needs-container">
                <div className="row">
                    <div className="text">
                        <h2>Project needs & requirements</h2>
                        <p>Our company is still growing so we need some help to achieve our goals</p>
                    </div>
                </div>
                <div className="row">
                    <div className="number">
                        1
                        </div>
                    <div className="text">
                        <h3>PACS partners</h3>
                        <p>We are seeking introductions to PACS systems manufacturers. Our goal is to integrate our solution into major PACS systems through APIs.</p>
                    </div>
                </div>
                <div className="row">
                    <div className="number">
                        2
                        </div>
                    <div className="text">
                        <h3>X-ray partners</h3>
                        <p>We are looking for people who can introduce us to X-ray equipment and software developers who can help us implement our solution into the equipment. Our goal is to find co-developers to ensure cross-platform compatibility.</p>
                    </div>
                </div>
                <div className="row">
                    <div className="number">
                        3
                        </div>
                    <div className="text">
                        <h3>Investment</h3>
                        <p>We are pursuing private venture capital to obtain the financing required to produce the first fully functioning version, based on our prototype.</p>
                    </div>
                </div>
                <div className="row">
                    <div className="number">
                        4
                        </div>
                    <div className="text">
                        <h3>X-ray data</h3>
                        <p>We are currently searching for partnerships with equine clinics and veterinarians to support us with their X-ray data. A large amount of data is required for prototype training to reach the high accuracy and efficiency of our system. Of course, all radiographs are anonymized, so that the personal data of your clients is protected.</p>
                    </div>
                </div>
            </div>



            <div className="who-we-are">
                <div className="block-area">
                    <div className="block-row">
                        <div className="block">
                            <h5>Startup</h5>
                            <p>LP “Innovatio veterinariae” is a company that develops advanced veterinary technology solutions. It is based at a startup incubator “Startup Space” within the National Innovation & Entrepreneurship Centre’s Science Valley in Kaunas, Lithuania.</p>
                        </div>
                        <div className="block">
                            <h5>What?</h5>
                            <p>Our core project - “EQUUSIGHT” - is an artificial intelligence-based system for automated equine X-ray analysis.</p>
                        </div>
                    </div>
                    <div className="block-row">
                        <div className="block">
                            <h5>How?</h5>
                            <p>EQUUSIGHT has been introduced at various national and international events, forums and conferences including those organised by the European Innovation and Technology Institute and world leading medical enterprise “GE Healthcare”.</p>
                        </div>
                        <div className="block">
                            <h5>Partners</h5>
                            <p>We have obtained three partnerships with large equine clinics in France and Denmark, as well as a private veterinarian from Portugal, who have been sharing their anonymised equine radiographs to train our AI model. </p>
                        </div>
                    </div>
                    <div className="block-row">
                        <div className="block">
                            <h5>Advisors</h5>
                            <p>The enterprise is guided by a strong advisory board from key market players, including artificial intelligence specialists from "Oxipit", diagnostic imaging software developers at "Softneta" and various equine clinicians across Europe. </p>
                        </div>
                        <div className="block">
                            <h5>Funds</h5>
                            <p>Our project has been funded by the European Union grant 01.2.1-MITA-T-852 „Inostartas“.</p>
                        </div>
                    </div>
                </div>
            </div>



            <div className="brochure">
                <div className="words">
                    <h1 className="main-title">WE'VE PUT TOGETHER A BROCHURE FOR YOU:</h1>
                    <a className="button" href={brochure} download>
                        DOWNLOAD
                        </a>
                </div>
            </div>
        </div>

    );

}

export default Home;